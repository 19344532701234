<template>
  <div class="auth-page register">
    <div class="auth-page__content my-auto">
      <h1 class="logo mb-5">POSIQ</h1>
      <input-field v-model="firstName" id="firstname" type="text" class="mb-4" label="Firstname"></input-field>
      <input-field v-model="lastName" id="lastname" type="text" class="mb-4" label="Lastname"></input-field>
      <hr class="mb-4">
      <input-field v-model="username" id="username" autocomplete="off" type="text" class="mb-4" label="Username"></input-field>
      <input-field v-model="password" id="password" autocomplete="off" type="password" class="mb-4" label="Password"></input-field>
      <input-field v-model="confirmPassword" id="confirmPassword" autocomplete="off" type="password" class="mb-4" label="Confirm password"></input-field>
      <hr class="mb-4">
      <input-field v-model="imeiNumber" id="imieNumber" type="text" class="mb-4" label="IMIE-number">
        <template #right-label><span class="action-small">Where can I find it?</span></template>
      </input-field>
      <hr class="mb-4">
      <input-field v-model="address" id="address" type="text" class="mb-4" label="Address"></input-field>
      <input-field v-model="zipCode" id="zipCode" type="text" class="mb-4" label="Zipcode"></input-field>
      <input-field v-model="city" id="city" type="text" class="mb-4" label="City"></input-field>
      <input-field v-model="country" id="country" type="text" class="mb-4" label="Country"></input-field>
      <input-field v-model="phone" id="phone" type="text" class="mb-5" label="Phone"></input-field>
      <app-button :loading="loading" @click="register" class="btn btn--block btn--blue">Let's do this!</app-button>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/shared/InputField";
import AppButton from "@/components/shared/AppButton";
import authService from '@/http/auth';

export default {
  components: {AppButton, InputField},
  data() {
    return {
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      confirmPassword: '',
      imeiNumber: '',
      address: '',
      zipCode: '',
      city: '',
      country: '',
      phone: '',
      loading: false
    }
  },
  methods: {
    validate() {
      if(this.username === '') {
        this.$notify.warning('Username is required!');
        return true;
      }

      if(this.password === '') {
        this.$notify.warning('Password is required!');
        return true;
      }

      if(this.confirmPassword !== this.password) {
        this.$notify.warning('Confirm password must match password!');
        return true;
      }

      if(this.imeiNumber === '') {
        this.$notify.warning('IMEI number is required!');
        return true;
      }

      return false;
    },
    async register() {

      if(this.loading) return;

      const hasError = this.validate();
      if(hasError) return;

      const postData = {
        "firstname": this.firstname,
        "lastname": this.lastname,
        "username": this.username,
        "password": this.password,
        "password_repeat": this.confirmPassword,
        "imei_number": this.imeiNumber,
        "address": this.address,
        "zipcode": this.zipcode,
        "city": this.city,
        "country": this.country,
        "phone": this.phone
      }

      try {
        this.loading = true;
        await authService.signup(postData);
        await this.$router.push('/login');
      } catch (e) {
        this.notify.error('Something went wrong!');
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/scss/_auth-pages.scss";
.register {
  padding: 50px 20px;
}
.auth-page__content {
  padding-bottom: 50px;
}
</style>
